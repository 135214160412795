import Vue from 'vue';
import App from './App.vue';
//import ethWeb from './EthWeb3/EthWeb.js';
import store from './store'
import router from './router'
import Vuetify from 'vuetify'
//import VueRouter from 'vue-router'
import 'vuetify/dist/vuetify.min.css'
import 'font-awesome/css/font-awesome.min.css'
import '@mdi/font/css/materialdesignicons.css'
import { sync } from 'vuex-router-sync'
Vue.config.productionTip = false;
Vue.use(Vuetify)
sync(store, router);
//Vue.prototype.$ethWeb = ethWeb;
//ethWeb.Init();
//ethWeb.getCount();
new Vue({
    vuetify: new Vuetify(),
    store,
    router,
    data: {
        count:100
    },
    render: h => h(App),
    methods: {

    }
}).$mount('#app');
