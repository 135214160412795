<template>
  <div id="app"  class="blue lighten-5"  style="min-height: 100%;background-color:red;">
    <!--
  // <Home msg="Hello world!" top-toolbar left-sidebar/> clipped-left
       -->
    <v-app id="inspire">

      <!--<v-navigation-drawer clipped app></v-navigation-drawer>-->
      <!--<Sidebar :drawer="drawer" :isSmallScreen="isSmallScreen" :isIconClicked="isIconClicked"></Sidebar>-->

      <v-app-bar color="blue" app dark>
        <v-app-bar-nav-icon v-on:click="showSidebar()"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        
        <!--<v-toolbar-items x-small color="red">  </v-toolbar-items>-->
        <div style="display:flex;text-align:center;align-items:center;">
          <div style="margin:0px 20px 0px 0px;">
            <v-btn depressed outlined rounded small variant="outlined" color="#BDBDBD" v-on:click="bloggerClick">
              <v-icon class="mr-2">
                mdi-babel
              </v-icon>
              blog
            </v-btn>
          </div>
          <div v-if="isUnlocked">
            <v-chip class="ma-2"
                    color="green darken-4"
                    text-color="white">
              {{this.$store.state.web3.chainName}}
            </v-chip>
            <!--<v-btn color="green darken-4" depressed small v-on:click="getCount">
      {{this.$store.state.web3.chainName}}
    </v-btn>-->
            <v-btn rounded color="brown darken-3" class=" white--text" outlined dark>
              {{ abbrCoinbase }}
            </v-btn>
            <label outlined for="checkbox">
              {{balance}}
            </label>
          </div>
          <div v-else>
            <v-btn depressed rounded color="success" v-on:click="connectWeb3">连接钱包</v-btn>
          </div>
        </div>
        

        <!--<v-btn @click="setting=!setting" text>
          <v-icon>mdi-palette</v-icon>
        </v-btn>
        <v-btn text>
          <v-icon>mdi-logout</v-icon>
        </v-btn>-->


      </v-app-bar>
      <myNavigation :miniNav="miniNav" :drawer="drawer" :backgroundNav="backgroundNav" />
      <!--<v-content dark>   v-if="item.title!='Home'"  justify-space-between   </v-content>-->
      <v-content >
        <v-expand-transition>
          <v-tabs color="pink" height="30px" style="position: sticky;  z-index: 5" v-show="tabsView && tabList.length" show-arrows>
            <v-tab :name="i" color="#f0f2f5" class="fluid  mr-1 pa-2  red lighten-5" v-for="(item, i) in tabList" :key="item.name" :to="item.path">
              {{ item.title}}
              <v-icon v-if="item.name!='Home'" size="15" style="{background-color:#BB86FC; }" @click.stop.prevent="closeTab(i)" @contextmenu.stop.prevent="">mdi-close</v-icon>

              <!--<v-hover v-slot="{ isHover }">
    <v-icon v-if="item.name!='Home'" size="15" style="{'background-color': isHover ? 'blue' : '#BB86FC' }" @click.stop.prevent="closeTab(i)" @contextmenu.stop.prevent="">mdi-close</v-icon>
  </v-hover>-->
            </v-tab>
          </v-tabs>
        </v-expand-transition>
        <keep-alive>
          <router-view ></router-view>
        </keep-alive>
        <v-row class="red lighten-5 align-left justify-end mt-1">
          <!--<v-col cols="auto" class="mr-8 pr-8">
    <v-btn color="#757575" icon @click="onClick_pcWinDownLoad">
      <v-icon left>
        mdi-navigation-variant
      </v-icon>
      电报
    </v-btn>
  </v-col>
  <v-col cols="auto" class="mr-8 pr-8">
    <v-btn color="#757575" icon class="pr-8"  @click.native="onClick_youtubeLoad">
      <v-icon left>
        mdi-play
      </v-icon>
      youtube
    </v-btn>
  </v-col>-->
          <div class="mr-8 pr-8">
            <v-btn color="#BDBDBD" icon @click="onClick_telegramLoad">
              <v-icon left>
                mdi-navigation-variant-outline
              </v-icon>
              电报
            </v-btn>
          </div>
          <div class="mr-8 pr-8">
            <v-btn color="#BDBDBD" icon class="pr-8" @click="onClick_gitHubLoad">
              <v-icon left>
                mdi-github
              </v-icon>
              Github
            </v-btn>
          </div>
          <div class="mr-8 pr-8">
            <v-btn color="#BDBDBD" icon class="pr-8" @click.native="onClick_youtubeLoad">
              <v-icon left>
                mdi-play
              </v-icon>
              youtube
            </v-btn>
          </div>
        </v-row>
        <!--<v-btn x-small v-on:click="getCount">{{networkId}}</v-btn>-->
      </v-content>

      <!--<v-content class="divder pb-12" :style="background" background="red">
      <keep-live>
          <button v-on:click="getCount">111kk</button>
      </keep-live>
  </v-content>-->
      <!--<v-footer app></v-footer>-->
    </v-app>
    <!--<v-app>

      <v-btn color="primary" small>新增</v-btn>
  </v-app>
  <h1>kkkk</h1>
  <button v-on:click="getCount">111kk</button>-->
    <v-snackbar v-model="snackbar">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
    
</template>

<script>
  //import Sidebar from './views/layout/Sidebar.vue'
  import myNavigation from './views/layout/navigation.vue'
  //  import Home from './components/Home.vue';
  import ethWeb from './EthWeb3/EthWeb';// eslint-disable-line no-unused-vars
  import hdWalletWeb3 from './util/hdWalletWeb3'
  import { mapState } from 'vuex'
    //var ethWeb = new EthWeb();// eslint-disable-line no-unused-vars
    //var testClass = new Person();// eslint-disable-line no-unused-vars
  export default {
    name: 'app',
    components: {
      //Home,
      myNavigation
    },
    data() {
      return {
        drawer: true,
        tabList: [],
        tabsView: true,
        isIconClicked: false,
        isSmallScreen: false,
        backgroundNav: true,
        miniNav: false,
        snackbar: false,
        snackText: `Hello, I'm a snackbar`,
        isHover: false
      }
    },
    mounted() {
      
      this.tabsView = JSON.parse(localStorage.getItem('tabsView') || true)
      this.initApp();
      this.handleResize();
      this.isHover = false;
      window.addEventListener('resize', this.handleResize);
      console.log(this.tabList);
      console.log("beforeunload12334");
      window.addEventListener("beforeunload", () => {
        console.log(this.$store);

        sessionStorage.setItem("chainName", JSON.stringify(this.$store.state.web3.chainName));
        sessionStorage.setItem("isUnlocked", JSON.stringify(this.$store.state.web3.isUnlocked));
        
        
        //if (event) {
        //  event.returnValue = '关闭提示';
        //}
      });
      //this.fn();          

    },
    computed: {
      ...mapState({
        isInjected: state => state.web3.isInjected,
        isUnlocked: state => state.web3.isUnlocked,
      networkId: state => state.web3.networkId,
      coinbase: state => state.web3.coinbase,
      balance: state => state.web3.balance,
      ethBalance: state => {
        if (state.web3.web3Instance !== null) {
          let balance = state.web3.web3Instance().utils.fromWei(state.web3.balance.toString(), 'ether').toString().substring(0,7)
          return balance
        }
        },
        abbrCoinbase: state => {
          console.log(state.web3.coinbase)
          let add=String(state.web3.coinbase)
          return add.substring(0, 4) + "**" + add.substring(add.length - 3, add.length)
        }
      }),
      getChainName() {
        console.log("net")
        return this.$store.state.web3.networkId;
      },
      getWeb3Error() {
        console.log("net")
        return this.$store.state.web3.error;
      }
    },  
    watch: {
      getChainName(newValue, oldValue) {
        console.log(newValue);
        console.log(oldValue);
      },
      getWeb3Error(newValue, oldValue) {
        if (newValue != null) {
          this.isHover = false;
          this.snackbar = true;
          this.snackText = this.$store.state.web3.error;
          this.$store.state.web3.error = null;
        } else {
          console.log(newValue);
        }
       
        console.log(oldValue);
      },
      $route(to) {
        // 查找tabs里面是否已经包含该路由
        let isCover = this.tabList.some((val) => {
          if (val.name==null)//如果找不到对应路由跳出
            return false;
          return val.name == to.name
        });
        !isCover && this.tabList.push({
          name: to.name,
          path: to.path,
          title: to.meta
        })
      },
      tabsView(val) {
        localStorage.setItem('tabsView', val)
      }
    },
    methods: {
      initApp: function () {
        if (this.$route.name != null) {
          this.tabList.push({
            name: this.$route.name,
            path: this.$route.path,
            title: this.$route.meta
          })
        }
        
        console.log(this.$route);
        console.log("切换", JSON.parse(sessionStorage.getItem("chainName")));
        this.$store.state.chainName = JSON.parse(sessionStorage.getItem("chainName"));
        let isUnlocked = JSON.parse(sessionStorage.getItem("isUnlocked"));
        console.log("切换", isUnlocked);
        //if (isUnlocked === true) {
          //hdWalletWeb3.initMetaMask();
        //}
      },
      closeTab(index) {
        this.tabList.splice(index, 1)
        this.$router.push(this.tabList[this.tabList.length - 1].path)
      },
      connectWeb3: function () {
        hdWalletWeb3.initMetaMask();
        console.log(this.$store);
        //this.$store.dispatch('registerWeb3');
      },
      getCount: function () {
        //ethWeb.getCount();
        console.log(this.$route);
        let result= hdWalletWeb3.initMetaMask();
        //this.$store.dispatch('registerWeb3');
        console.log(result);
        console.log(this.$store);
      },
      bloggerClick() {
        window.open('https://www.blog.bbctool.com/','_blank')
      },
      handleResize() {
        
      //console.log("切换");
      //    let width = (window.innerWidth > 0) ? window.innerWidth : screen.width
      //    if (width < 1024) {
      //      this.isSmallScreen = true
      //      if (this.drawer===true)
      //        this.drawer = false
      //      this.removeOverlay()
      //    } else {
      //        this.isSmallScreen = false
      //        this.drawer = true
      //        this.removeOverlay()
      //    }
      },

      showSidebar() {
        if (this.drawer == true) {
          this.drawer = false;
        } else {
          this.drawer = true;
        }
        
      },

      removeOverlay() {
          let $overlay = document.getElementsByClassName('overlay')
          if ($overlay.length > 0) {
              $overlay[0].classList.remove('overlay--active')
          }
      },
      onClick_telegramLoad() {
        window.open("https://t.me/bbctool");
      },
      onClick_gitHubLoad() {
        window.open("https://github.com/bbctool");
      },
      onClick_youtubeLoad() {
        window.open("https://www.youtube.com/channel/UC4kUl39pJ9ZiwsvJVq5QQfg");
      }
      //fn() {
      //    ethereum.on("accountsChanged", function (accounts) {
      //        console.log("切换" + accounts[0]);//一旦切换账号这里就会执行
      //    });
      //}
  }
};
//window.addEventListener("load", function () {
   
//    //Person.methds.startq();
//    //ethWeb.start();//@import 'css/google-material-icons.css';
        
//});
  //在页面刷新时将vuex里的信息保存到localStorage里
  
  //window.onbeforeunload = event => {
  //  console.log(this);
  //  console.log('onbeforeload！！！！！');
  //  console.log(this);
  //  if (event) {
  //    event.returnValue = '关闭提示';
  //  }
  //};

</script>

<style lang="scss">
</style>

