<template>
  <v-navigation-drawer width="220"
                       :mini-variant="miniNav"
                       mini-variant-width="64"
                       :color="backgroundNav?'primary':null"
                       :dark="backgroundNav"
                       hide-overlay
                       v-model="showSidebar"
                       value="false"
                       app>
    <!--src="../assets/background.png"-->
    <v-list flat>
      <template v-for="list in navList">
        <v-list-group class="white--text"
                      v-if="list.items.length"
                      :key="list.path"
                      :prepend-icon="list.icon"
                      :active-class="backgroundNav?'white--text':'grey--text text--darken-3'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{list.title}}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="item in list.items"
                       :key="item.title"
                       active-class="secondary white--text"
                       :to="item.path" v-on:click="getAccounts">
            <v-list-item-action><v-icon>{{item.icon}}</v-icon></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item :key="list.title"
                     active-class="secondary white--text"
                     :to="list.path"
                     v-else>
          <v-list-item-action><v-icon>{{list.icon}}</v-icon></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{list.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'myNavigation',
    props: {
      miniNav: Boolean,
      backgroundNav: Boolean,
      drawer: Boolean
    },
    methods: {
      getAccounts: function () {
        console.log(this.$route);
        console.log(this.$route.name);
        console.log(this.$store.state);
       
        //hdWeb3.signHdWallet(this.$route.name);
      }
    },
    
    data() {
      return {
        showSidebar: this.drawer,
        //chainMap1,
        navList: [
          {
            title: '首页',
            icon: 'mdi-home-circle',
            path: '/home',
            group: 'home',
            items: []
          },
          {
            title: '批量转账',
            icon: 'mdi-database-export',
            group: '/views',
            items: [{
              title: 'ETH',
              path: '/BatchTransfer/ETH',
              chainId:'1',
              icon: 'mdi-alpha-e-circle-outline'
            },
            {
              title: 'BSC',
              path: '/BatchTransfer/BSC',
              chainId: '1',
              icon: 'mdi-alpha-b-circle-outline'
            },
            {
            title: 'HT',
              path: '/BatchTransfer/HT',
              chainId: '1',
              icon: 'mdi-alpha-h-circle-outline'
            },
            {
              title: 'OK',
              path: '/batchTransfer/ok',
              chainId: '1',
              icon: 'mdi-alpha-o-circle-outline'
            },
            {
              title: 'MATIC',
              path: '/batchTransfer/matic',
              chainId: '1',
              icon: 'mdi-alpha-m-circle-outline'
              },
              {
                title: 'CORE',
                path: '/batchTransfer/Core',
                chainId: '1',
                icon: 'mdi-alpha-c-circle-outline'
              },
              {
                title: 'WETH',
                path: '/batchTransfer/WETH',
                chainId: '1',
                icon: 'mdi-alpha-w-circle-outline'
              },
              {
                title: 'ARBITRUMONE',
                path: '/batchTransfer/ArbitrumOne',
                chainId: '1',
                icon: 'mdi-alpha-a-circle-outline'
              },
            {
              title: 'QKI',
              path: '/batchTransfer/qki',
              chainId: '1',
              icon: 'mdi-alpha-q-circle-outline'
              },
              {
                title: 'MBK',
                path: '/batchTransfer/mbk',
                chainId: '1',
                icon: 'mdi-alpha-m-circle-outline'
              },
              {
                title: 'TBSC',
                path: '/batchTransfer/tbsc',
                chainId: '1',
                icon: 'mdi-material-design'
              },
              {
                title: 'THECO',
                path: '/batchTransfer/theco',
                chainId: '1',
                icon: 'mdi-material-design'
              }
            ]
          },
          {
            title: '合约安全检测',
            icon: 'mdi-security',
            path: '/contractGoPlusCheck',
            group: 'home',
            items: []
          },
          {
            title: 'NFT一键发布',
            icon: 'mdi-puzzle-heart',
            path: '/nFTMain',
            group: 'NFTMain',
            items: []
          }
        //  {
        //  title: '应用探索', /contractGoPlusCheck
        //  icon: 'mdi-speedometer',
        //  group: '/views',
        //  items: [{
        //    title: 'Casino',
        //    path: '/casino',
        //    icon: 'mdi-triangle-outline'
        //  }, {
        //    title: '材质图标',
        //    path: '/about',
        //    icon: 'mdi-material-design'
        //  }]
        //}, {
        //  title: '思维进阶',
        //  icon: 'mdi-lightbulb-outline',
        //    group: '/views',
        //  items: [{
        //    title: '转场动画',
        //    path: '/about',
        //    icon: 'mdi-transition-masked'
        //  }]
          //}
        ]
      }
    },
    computed: {
      ...mapState({
        
      })
    },
    watch: {
      drawer() {
        this.showSidebar = this.drawer
        console.log('drawer: ' + this.showSidebar)
      }
    }
  }
</script>
<style>
</style >
