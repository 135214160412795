import Web3 from 'web3'
import store from '../store/'
import { MultiABI } from './contractabi/MultiSendABI'
import { ErcStABI } from './contractabi/Erc20StABI'
//const EthereumTx = require('ethereumjs-tx').Transaction
export default {
  contractAddress: 'http://127.0.0.1:7545',// eslint-disable-line no-unused-vars

  //const contractABI = []
  //var contract = null;
  //Vue.use(Web3); 
  hdweb3: null,// eslint-disable-line no-unused-vars
  account: null,
  meta: null,
  balance: 0,
  initMetaMask: function () {
    store.state.web3.chainName = '';
    if (typeof window.ethereum !== 'undefined') {
      console.log("initMetaMask----",'MetaMask is installed!');
      // 实例化web3
      window.web3 = new Web3(window.ethereum);
      window.ethereum.on('connect', (connectInfo) => {
        if (window.ethereum.isConnected()) {
          // 确定提供程序何时/是否连接。
          console.log("initMetaMask----connect1", connectInfo)
        } else {
          console.log("initMetaMask----connect0", connectInfo)
        }
       
      });
      window.ethereum.on('disconnect', (error) => {
        console.log("initMetaMask----disconnect", error)
      });
      // 请求用户授权 解决web3js无法直接唤起Meta Mask获取用户身份
      window.ethereum.enable().then(function (accounts) {
        // You now have an array of accounts!
        // Currently only ever one:
        // ['0xFDEa65C8e26263F6d9A1B5de9555D2931A33b825']
       // Message.warning("There was a problem signing you in");
        console.log("initHdwallet-enable", accounts[0])
        console.log("initHdwallet-",window.ethereum.networkVersion)
        store.state.web3.networkId = window.ethereum.networkVersion;
        let chainInfo = store.state.chainMapNetworkId.get(parseInt(store.state.web3.networkId));
        console.log("initHdwallet-",chainInfo)
        if (chainInfo != null) {
          store.state.web3.chainName = chainInfo.name;
        }
        store.state.web3.web3Instance = window.web3;
        //hdWalletWeb.getStateInfo.apply()  .substring(0, 7)
        store.state.web3.coinbase = accounts[0]
        store.state.web3.isUnlocked = true;
        store.state.web3.isInjected = true;
        store.state.web3.error = null
        window.ethereum.on('accountsChanged', (accounts) => {
          console.log("initHdwallet-accountsChanged", accounts[0])
          store.state.web3.coinbase = accounts[0]
          store.state.web3.web3Instance.eth.getBalance(store.state.web3.coinbase, (err, balance) => {
            console.log(balance)
            if (err) {
              console.log("initHdwallet-getBalance", err)
            } else {
              store.state.web3.balance = balance
              store.state.web3.balance = store.state.web3.web3Instance.utils.fromWei(balance.toString(), 'ether').toString().substring(0, 7)
              console.log("initHdwallet-getBalance", store.state.web3.balance)
            }
          })
        });
        store.state.web3.web3Instance.eth.getBalance(store.state.web3.coinbase, (err, balance) => {
          console.log(balance)
          if (err) {
            console.log("initHdwallet-getBalance", err)
          } else {
            store.state.web3.balance = balance
            store.state.web3.balance = store.state.web3.web3Instance.utils.fromWei(balance.toString(), 'ether').toString().substring(0, 7)
            console.log("initHdwallet-getBalance", store.state.web3.balance)
          }
        })
        store.state.web3.web3Instance.eth.getGasPrice()
          .then(result => {
            store.state.web3.gasPrice = store.state.web3.web3Instance.utils.fromWei(result.toString(), 'gwei');
            chainInfo.gasPrice = store.state.web3.gasPrice;
            console.log(store.state.web3.gasPrice);
          });
        window.ethereum.on('chainChanged', (chainId) => {
          // Handle the new chain.
          // Correctly handling chain changes can be complicated.
          // We recommend reloading the page unless you have good reason not to.
          console.log("chainChanged", chainId);
          store.state.web3.networkId = chainId;
          
          window.location.reload();
        });
      }).catch(function (reason) {
        //点击解锁后，未解锁，报该错误
          // Handle error. Likely the user rejected the login:
          store.state.web3.isInjected = false;
          store.state.web3.error = reason.message;
          store.state.web3.errorCode = reason.code;
          console.log(store.state.web3.error);
          console.log(reason, "User rejected provider access")
          
        })      
      
      
    } else {     
      store.state.web3.isInjected = false;
      console.log('MetaMask is no installed!');
    }
    //console.log("initHdwallet", store.state.web3.isInjected)
    
  },
  async switchChain(networkId) {
    let chainId ="0x" + networkId.toString(16);
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      console.log("switchChain", switchError);
      if (switchError.code === 4902) {
        try {
          let chainInfo = store.state.chainMapNetworkId.get(networkId);
          console.log("wallet_addEthereumChain", chainInfo);
          let arrRpc = [chainInfo.rpcUrl];
          let arrExplorer = [chainInfo.scanUrl]
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chainId,
                chainName: chainInfo.name,
                rpcUrls: arrRpc,
                nativeCurrency: {
                  name: chainInfo.name,
                  symbol: chainInfo.symbol, // 2-6 characters long
                  decimals: chainInfo.decimal
                },
                blockExplorerUrls: arrExplorer// ['https://bscscan.com/'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
          console.log("wallet_addEthereumChain", addError);
        }
      }
      // handle other "switch" errors
    }
  },
  //获得tokenbalance
  getTokenBalance: function (erc20Add, address) {
    let erc20Contract = new store.state.web3.web3Instance.eth.Contract(ErcStABI, erc20Add);
    erc20Contract.methods.decimals().call().then(decimalsRes => {
      let decimals = decimalsRes;
      erc20Contract.methods.balanceOf(address).call().then(balance => {
        let xWei = this.getEthUnit(decimals)

        store.state.web3.tokenBalance = store.state.web3.web3Instance.utils.fromWei(balance.toString(), xWei);
      })
    })
  },  
  //获得合约精度及发行总量
  getTotalSupply: function (erc20Add) {
    let erc20Contract = new store.state.web3.web3Instance.eth.Contract(ErcStABI, erc20Add);
    erc20Contract.methods.decimals().call().then(decimalsRes => {
      let decimals = decimalsRes;
      store.state.web3.unitDecimal = decimals;
      store.state.web3.unitEth = this.getEthUnit(Number(decimals));
      console.log("unitEth", store.state.web3.unitDecimal);
      console.log("unitEth", store.state.web3.unitEth);
      erc20Contract.methods.totalSupply().call().then(totalSupply => {        
        let xWei = this.getEthUnit(decimals)
        store.state.web3.totalSupply = store.state.web3.web3Instance.utils.fromWei(totalSupply.toString(), xWei);
        console.log("getTotalSupply",totalSupply);
      })
    })
  },
  //获得MultiSend打包个数
  getPackCount: function (multiAdd) {
    let erc20Contract = new store.state.web3.web3Instance.eth.Contract(MultiABI, multiAdd);
    console.log("erc20Contract", erc20Contract);
    erc20Contract.methods.packCount().call().then(packCount => {
      console.log("packCount", packCount);
      store.state.web3.packCount = packCount;
          
    })
  },
  sendChainToken: function (toAddress, value,unitdecimal=18) { 
    //转账数量
    let amount = value * Math.pow(10, unitdecimal);
    let gasPrice = store.state.web3.web3Instance.utils.toWei(store.state.web3.gasPrice, 'gwei');
    store.state.web3.web3Instance.eth.sendTransaction({
      type:'0x2',// 0x0 为erc20转账  不填0x2  eip-1559
      gas: 91000,
      gasPrice: gasPrice,
      from: store.state.web3.coinbase,
      to: toAddress,
      value: amount
    }, (err, result) => {
      console.log("转账Hash=", result)
    })   
  },
  getMultiContract: function (address) {
    store.state.web3.contractInstance = new store.state.web3.web3Instance.eth.Contract(MultiABI, address)
    //console.log(store.state.web3.contractInstance.methods.packCount)
    store.state.web3.contractInstance.methods.owner().call((err, result) => {
      if (err) {
        console.log(err)
        this.pending = false
      } else {
        console.log(result)        
      }
    })
  },
  sendMultiEth: function (multiAdd, addressArray, valuesArray, msgValue) {
    store.state.web3.contractInstance = new store.state.web3.web3Instance.eth.Contract(MultiABI, multiAdd)
    let gasPrice = store.state.web3.web3Instance.utils.toWei(store.state.web3.gasPrice, 'gwei');
    let gasLimit;
    console.log(gasPrice);
    let singleAddressArray = [];
    let singleValuesArray = [];
    let multiTbItem = store.state.multiTbItems[store.state.multiTbItemsCurrentIndex];
    let startIndex = multiTbItem.startIndex;
    let endIndex = multiTbItem.endIndex;
    let singleTotalAmount = 0;
    for (let j = startIndex; j < endIndex; j++) {
      singleTotalAmount = Number(singleTotalAmount) + Number(valuesArray[j]);
      singleAddressArray.push(addressArray[j]);
      singleValuesArray.push(valuesArray[j]);
    }
    msgValue = Number(msgValue) + Number(singleTotalAmount);
    console.log("singleAddressArray", singleAddressArray);
    console.log("singleValuesArray", singleValuesArray);
    console.log("singleValuesArray-msgValue", msgValue);
    store.state.multiTbItemsCurrentIndex = store.state.multiTbItemsCurrentIndex + 1;
    console.log("multiTbItemsCurrentIndex", store.state.multiTbItemsCurrentIndex);
    let multiItem = store.state.multiTbItems[store.state.multiTbItemsCurrentIndex - 1];
    store.state.web3.contractInstance.methods.sendEth(singleAddressArray, singleValuesArray).estimateGas({ from: store.state.web3.coinbase, value: msgValue, gasPrice: gasPrice, type: '0x2' }).then((gasAmount) => {
      if (gasAmount) {
        console.log("estimateGasEth", gasAmount)
        gasLimit = gasAmount;
        console.log(gasLimit);
        store.state.web3.contractInstance.methods.sendEth(singleAddressArray, singleValuesArray).send({ from: store.state.web3.coinbase, value: msgValue, gas: gasLimit, gasPrice: gasPrice, type: '0x0' })
          .on('transactionHash', function (hash) {
            console.log('transactionHash', hash);
            multiItem.state = 1;
            multiItem.message = "已发送";
            multiItem.hash = hash;
          }).on('confirmation', function (confirmationNumber, receipt) {
            console.log('confirmation', confirmationNumber);
            console.log('confirmation', receipt);
            if (confirmationNumber == 1)
              multiItem.state = 2;
              multiItem.message="节点确认成功";
          }).on('receipt', function (receipt) {
            console.log('receipt', receipt.status);
            if (receipt.status == 1) {
              multiItem.state = 3;
              multiItem.message = "发送成功!";
            }
            console.log('receipt', receipt.blockNumber);
            console.log('receipt-------', receipt);
            arguments.callee(multiAdd, addressArray, valuesArray, msgValue);
          }).on('error', function (error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.log('receipt', receipt);
            console.log('receipt', error);
            
            multiItem.state = 4;
            multiItem.message = "已取消发送";
            console.log('receipt', multiItem);
          });
      } else {
        multiItem.state = 5;
        multiItem.message = "获取GasLimit异常";
        console.log("err:", gasAmount)
      }
    }).catch(function (error) {
      multiItem.state = 5;
      multiItem.message = "获取GasLimit异常";
      console.log('estimateGas', error);
    });

  },
  sendMultiEth1: function (multiAdd,address,values,msgValue) {
    store.state.web3.contractInstance = new store.state.web3.web3Instance.eth.Contract(MultiABI, multiAdd)
    let gasPrice = store.state.web3.web3Instance.utils.toWei(store.state.web3.gasPrice, 'gwei');
    let gasLimit;
    console.log(gasPrice)
    //const txParams = {      
    //  gasPrice: gasPrice,
    //  gasLimit: 89000,
    //  to: contractAdd,
    //  value: msgValue,
    //  data: '',
    //}
    //const tx = new EthereumTx(txParams, { chain: 'mainnet', hardfork: 'petersburg' })
    //tx.sign(privateKey)
    //const serializedTx = tx.serialize()
    //const raw = '0x' + serializedTx.toString('hex')

    //// 广播交易
    //web3.eth.sendSignedTransaction(raw, (err, txHash) => {
    //  console.log('txHash:', txHash)
    //  // 可以去ropsten.etherscan.io查看交易详情
    //})
    //获得gas上限
    //store.state.web3.contractInstance.methods.sendEth(address, values).estimateGas({ from: store.state.web3.coinbase, value: msgValue, gasPrice: gasPrice, type: '0x2'},function(gasAmount){
    //  console.log("estimateGas" ,gasAmount);
    //})
    store.state.web3.contractInstance.methods.sendEth(address, values).estimateGas({ from: store.state.web3.coinbase, value: msgValue, gasPrice: gasPrice, type: '0x2' }).then((gasAmount) => {
      if (gasAmount) {

        console.log("estimateGasEth", gasAmount)
        gasLimit = gasAmount;
        console.log(gasLimit);
        store.state.web3.contractInstance.methods.sendEth(address, values).send({ from: store.state.web3.coinbase, value: msgValue, gas: gasLimit, gasPrice: gasPrice, type: '0x0' })
          .on('transactionHash', function (hash) {
            console.log('transactionHash',hash);
          }).on('confirmation', function (confirmationNumber, receipt) {
            console.log('confirmation', confirmationNumber);
            console.log('confirmation',receipt);
          }).on('receipt', function (receipt) {
            console.log('receipt', receipt.status);
            console.log('receipt', receipt.blockNumber);
            console.log('receipt',receipt);
          }).on('error', function (error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.log('receipt', receipt);
            console.log('receipt', error);
          });
        //  if (err) {
        //    console.log(err)
        //    this.pending = false
        //  } else {
        //    console.log(result)
        //  }
        //})
      } else {
        console.log("err:", gasAmount)
      }
      
    })
    
  },

  judgeMultiApprove: function (multiAdd, erc20Add, address, values, msgValue, totals, gasPrice) {
    console.log("judgeMultiApprove", gasPrice.toString())
    gasPrice = store.state.web3.web3Instance.utils.toWei(gasPrice.toString(), 'gwei');
    let gasLimit;
    let multiItem = store.state.multiTbItems[store.state.multiTbItemsCurrentIndex];
    store.state.web3.contractInstance = new store.state.web3.web3Instance.eth.Contract(MultiABI, multiAdd)
    let erc20Contract = new store.state.web3.web3Instance.eth.Contract(ErcStABI, erc20Add);
    erc20Contract.methods.allowance(store.state.web3.coinbase, multiAdd).call().then(allowance => {
      console.log("allowance---allowance", typeof(allowance))
      console.log("allowance---totals", typeof(totals))
      if (parseInt(allowance) < parseInt(totals)) {
        console.log("totals", totals)
        //需要授权
        erc20Contract.methods.approve(multiAdd, store.state.MaxApprove).estimateGas({ from: store.state.web3.coinbase, gasPrice: gasPrice, type: '0x0' }).then((gasAmount) => {
          if (gasAmount) {
            gasLimit = gasAmount;
            console.log("approve-estimate", gasAmount)
            erc20Contract.methods.approve(multiAdd, store.state.MaxApprove).send({ from: store.state.web3.coinbase, gas: gasLimit, gasPrice: gasPrice, type: '0x0' })
              .on('transactionHash', function (hash) {
                console.log("approve-transactionHash", hash)
                multiItem.hash = hash;
                multiItem.message = "开始授权";
              })
              .on('confirmation', function (confirmationNumber, receipt) {
                console.log("approve-confirmation", confirmationNumber)
                console.log("approve-confirmation", receipt)
                if (confirmationNumber ==2) {
                  if (receipt.status == true) {
                    multiItem.message = "授权成功";
                    multiItem.message = "请钱包确认转账!";
                    console.log("approve-sendMultiErc20In", multiItem)
                    multiItem.state = 8;
                    //if (multiItem.state == 1) {
                    //  console.log("approve-sendMultiErc20In------", multiItem)
                    //  multiItem.state = 2;
                    //  console.log("approve-sendMultiErc20In", multiItem)
                    //  this.sendMultiErc20In(erc20Add, address, values, msgValue, gasPrice)
                    //}
                  }
                }
              })
              .on('receipt', function (receipt) {
                console.log("approve-receipt", receipt)
                if (receipt.status == true) {
                  multiItem.message = "授权成功";
                }
              }).on('error', function (error, receipt) { // 如果交易被网络拒绝并带有交易收据，则第二个参数将是交易收据。
                console.log("approve", error);
                console.log("approve", receipt);
              });
          }
        })
      } else {
        //直接发送
        multiItem.state = 8;
        multiItem.message = "已经授权";
        console.log("allowance-------", allowance)
        //this.sendMultiErc20In(erc20Add, address, values, msgValue, gasPrice)
      }
    })
    
    
  },
  sendMultiErc20: function (erc20Add, addressArray, valuesArray, msgValue, gasPrice) {
    gasPrice = store.state.web3.web3Instance.utils.toWei(gasPrice.toString(), 'gwei');
    //let multiTbItem = store.state.multiTbItems[store.state.multiTbItemsCurrentIndex];
    let singleAddressArray = [];
    let singleValuesArray = [];
    let multiTbItem = store.state.multiTbItems[store.state.multiTbItemsCurrentIndex];
    let startIndex = multiTbItem.startIndex;
    let endIndex = multiTbItem.endIndex;
    let singleTotalAmount = 0;
    for (let j = startIndex; j < endIndex; j++) {
      singleTotalAmount = Number(singleTotalAmount) + Number(valuesArray[j]);
      singleAddressArray.push(addressArray[j]);
      singleValuesArray.push(valuesArray[j]);
    }
    console.log("sendMultiErc20In************", "开始");
    store.state.multiTbItemsCurrentIndex = store.state.multiTbItemsCurrentIndex + 1;
    console.log("multiTbItemsCurrentIndex", store.state.multiTbItemsCurrentIndex);
    let multiItem = store.state.multiTbItems[store.state.multiTbItemsCurrentIndex - 1];
    console.log("multiTbItemsCurrentIndex", singleAddressArray);
    let gasLimit;
    store.state.web3.contractInstance.methods.sendErc20(erc20Add, singleAddressArray, singleValuesArray).estimateGas({ from: store.state.web3.coinbase, value: msgValue, gasPrice: gasPrice, type: '0x0' }).then((gasAmount) => {
      if (gasAmount) {
        console.log("estimateGas", gasAmount)
        gasLimit = gasAmount;
        console.log(gasLimit);
        store.state.web3.contractInstance.methods.sendErc20(erc20Add, singleAddressArray, singleValuesArray).send({ from: store.state.web3.coinbase, value: msgValue, gas: gasLimit, gasPrice: gasPrice, type: '0x0' }).on('transactionHash', function (hash) {
          console.log('transactionHash', hash);
          multiItem.state = 2;
          multiItem.message = "已获取hash值";
          multiItem.hash = hash;
        }).on('confirmation', function (confirmationNumber, receipt) {
          console.log('confirmation', confirmationNumber);
          console.log('confirmation', receipt);
          if (confirmationNumber == 3)
            multiItem.state = 3;
          multiItem.message = "节点确认成功";
        }).on('receipt', function (receipt) {
          console.log('receipt', receipt.status);
          if (receipt.status == 1) {
            multiItem.state = 3;
            multiItem.message = "发送成功!";
          }
          console.log('receipt', receipt.blockNumber);
          console.log('receipt-------', receipt);
          //arguments.callee(multiAdd, addressArray, valuesArray, msgValue);
        }).on('error', function (error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
          console.log('receipt', receipt);
          console.log('receipt', error);

          multiItem.state = 4;
          multiItem.message = "已取消发送";
          console.log('receipt', multiItem);
        });
      } else {
        console.log("err:", gasAmount)
      }

    })
  },
  estimateGasMultiErc20: function (multiAdd, erc20Add, address, values, msgValue, gasPrice) {
    let gasGWei = store.state.web3.web3Instance.utils.toWei(gasPrice.toString(), 'gwei');
    console.log("estimateGasMultiErc20:", gasGWei)
    console.log("estimateGasMultiErc20:", multiAdd)
    console.log("estimateGasMultiErc20:", address)
    console.log("estimateGasMultiErc20:", values)
    store.state.web3.contractInstance = new store.state.web3.web3Instance.eth.Contract(MultiABI, multiAdd)
    store.state.web3.contractInstance.methods.sendErc20(erc20Add, address, values).estimateGas({ from: store.state.web3.coinbase, value: msgValue, gasPrice: gasGWei.toString(), type: '0x0' }).then((gasAmount) => {
      if (gasAmount) {
        store.state.web3.gasLimit = gasAmount * store.state.web3.web3Instance.utils.fromWei(gasGWei.toString(), 'ether')*1.8;
        console.log("estimateGasMultiErc20:", gasAmount)
      }
    })
  },
  estimateGasMultiEth: function (multiAdd, address, values, msgValue, gasPrice) {
    //let gasLimit;
    //let intPrice = Math.ceil(gasPrice);
    console.log("estimateGasMultiEth:", gasPrice)
    console.log("estimateGasMultiEth:", MultiABI)
    console.log("estimateGasMultiEth:", multiAdd)
    let gasGWei = store.state.web3.web3Instance.utils.toWei(gasPrice.toString(), 'gwei');
    store.state.web3.contractInstance = new store.state.web3.web3Instance.eth.Contract(MultiABI, multiAdd)
    store.state.web3.contractInstance.methods.sendEth(address, values).estimateGas({ from: store.state.web3.coinbase, value: msgValue, gasPrice: gasGWei, type: '0x0' }).then((gasAmount) => {
      if (gasAmount) {
        console.log("estimateGasMultiEth:", gasAmount)
        store.state.web3.gasLimit = gasAmount * store.state.web3.web3Instance.utils.fromWei(gasGWei.toString(), 'ether') * 1.8;
      }
    }).catch(function (error) {
      console.log("estimateGasMultiEth:", error)
    });
  },
  sendErc20Token: function (contractAdd, address, msgValue) {
    store.state.web3.contractInstance = new store.state.web3.web3Instance.eth.Contract(ErcStABI, contractAdd)
    let gasPrice = store.state.web3.web3Instance.utils.toWei("1", 'gwei');
    console.log(gasPrice)
    //const txParams = {      
    //  gasPrice: gasPrice,
    //  gasLimit: 89000,
    //  to: contractAdd,
    //  value: msgValue,
    //  data: '',
    //}
    //const tx = new EthereumTx(txParams, { chain: 'mainnet', hardfork: 'petersburg' })
    //tx.sign(privateKey)
    //const serializedTx = tx.serialize()
    //const raw = '0x' + serializedTx.toString('hex')

    //// 广播交易
    //web3.eth.sendSignedTransaction(raw, (err, txHash) => {
    //  console.log('txHash:', txHash)
    //  // 可以去ropsten.etherscan.io查看交易详情
    //})
    //获得gas上限
    store.state.web3.contractInstance.methods.transfer(address, msgValue.toString())
      .estimateGas({ from: store.state.web3.coinbase, gasPrice: gasPrice, type: '0x0' }).then((err, result) => {
        if (err) {
          console.log("estimateGas",err)
        } else {
          console.log("estimateGas",result)
        }
    } );
    //store.state.web3.contractInstance.methods.sendEth(address, values).send({ from: store.state.web3.coinbase, to: contractAdd, value: msgValue, gas: 98000, gasPrice: gasPrice, type: '0x0' }).then((err, result) => {
    //  if (err) {
    //    console.log(err)
    //    this.pending = false
    //  } else {
    //    console.log(result)
    //  }
    //})
  },
  getStateInfo: function () {
    this.hdweb3.eth.getCoinbase((err, coinbase) => {
      if (err) {
        console.log('Unable to retrieve coinbase')
      } else {
        console.log("initHdwallet", coinbase)
        console.log("initHdwallet", store)
        store.state.web3.coinbase = coinbase
        this.hdweb3.eth.getBalance(store.state.web3.coinbase, (err, balance) => {
          console.log(balance)
          if (err) {
            console.log("initHdwallet-getBalance", err)
          } else {
            store.state.web3.balance = balance
            store.state.web3.balance = this.hdweb3.utils.fromWei(balance.toString(), 'ether').toString().substring(0, 7)
            console.log("initHdwallet-getBalance", store.state.web3.balance)
          }
        })
        //resolve(coinbase)
      }
    })
  },
  connectChain: function () {
    console.log(this.hdweb3);
    if (this.hdweb3 != null) { //检查是否已有web3实例
      this.hdweb3 = new Web3(this.hdweb3.currentProvider);
    } else {
      //否则就连接到给出节点
      this.hdweb3 = new Web3();
      this.hdweb3.setProvider(new Web3.providers.HttpProvider("https://http-testnet.hecochain.com"));//https://http-mainnet.hecochain.com"));
    }
    //通过查询创世区块来判断是否连接成功
    this.hdweb3.eth.getBlock(0, function (error, result) {
      console.log(result);
      if (!error) {
        console.log("connection should be succeed");
        //this.getNodeAccounts();
      }
      else {
        console.log("something wrong,the connection might be failed" + result);
      }        
    })
  },
  getChainBalance: function () {
    this.hdweb3.eth.getBalance('0x4B9DA18dC72481F19b9B4D65c11c08f3A36d6B2c').then(function (balance) {
      console.log('balance:', balance);
    })
  },
  getNodeAccounts: function () {
    //let account0;
    this.hdweb3.eth.getAccounts(function (error, result) {
      if (!error) {
       // account0 = result[0];
        //console.log(account0);
        console.log("accounts:" + result);
      }
      else {
        console.log("failed to get Accoutns");
      }
    })
  },
  signHdWallet: function (chain) {
    console.log(chain);
    let fromAddress = "0x690F6e443d53BE8E73EC7EF091181df9E76D05f3";
    let toAddress = "0x3b4752a213a85DC8c32764A79022A7Fe79E9a9CD";
    let gasLimit = 29000;
    let value = 0x1;
    let inputData = "";
    const tx = {
      // this could be provider.addresses[0] if it exists
      from: fromAddress,
      // target address, this could be a smart contract address
      to: toAddress,
      // optional if you want to specify the gas limit 
      gas: gasLimit,
      // optional if you are invoking say a payable function 
      value: value,
      // this encodes the ABI of the method and the arguements
      data: inputData// myContract.methods.myMethod(arg, arg2).encodeABI()
    };
   
    const privateKey = "0x17ed7b8b4f600c36350d5f8f1915f4fbb7c8dc602c70d5fe40f7ae5c2b9cd638";
    const signPromise = this.hdweb3.eth.accounts.signTransaction(tx, privateKey);
    console.log(signPromise);
    signPromise.then((signedTx) => {

      // raw transaction string may be available in .raw or 
      // .rawTransaction depending on which signTransaction
      // function was called
      const sentTx = this.hdweb3.eth.sendSignedTransaction(signedTx.raw || signedTx.rawTransaction);

      sentTx.on("receipt", receipt => {
        // do something when receipt comes back
        console.log(receipt);
      });

      sentTx.on("error", err => {
        // do something on transaction error
        console.log(err);
      });

    }).catch((err) => {
      console.log(err);

      // do something when promise fails

    });
  },
  getEthUnit: function (decimal) {
    let xWei;
    switch (decimal) {
      case 18://ether: ‘1000000000000000000’
        xWei = "ether";
        break;
      case 15://finney: ‘1000000000000000’
        xWei = "finney";
        break;
      case 12://szabo: ‘1000000000000’
        xWei = "szabo";
        break;
      case 9://gwei: ‘1000000000’
        xWei = "gwei";
        break;
      case 6://mwei: ‘1000000’
        xWei = "mwei";
        break;
      case 3://kwei: ‘1000’
        xWei = "kwei";
        break;
    }
    return xWei;
  },
  getMultiAdd: function (chain) {
    let multiAdd;
    switch (chain) {
      case 'ETH'://eth
        multiAdd = "0x79593E894C808155D99e683ef2BAFa5D1661fb1E";
        break;
      case 'BSC'://ether: ‘1000000000000000000’
        multiAdd = "0x88e289ea44237b9dd9346d4579cdb97e98df3faf";
        break;
      case 'HT'://finney: ‘1000000000000000’
        multiAdd = "0x05D98BD3C45A8adA6cBD97410bc9B99E09e72E36";
        break;
      case 'OK'://szabo: ‘1000000000000’
        multiAdd = "0x94bB35F065f055cFc3E9F64b9e57FB2BD743bEc7";
        break;
      case 'MATIC'://gwei: ‘1000000000’
        multiAdd = "0x6E82d4A42a7532cFA6511625C0ad17c2fa2aa6c6";
        break;
      case 'CORE'://gwei: ‘1000000000’
        multiAdd = "0x48E18FcDd4519AD9cb09ccA14B3f0856BB659278";
        break;
      case 'WETH'://gwei: ‘1000000000’
        multiAdd = "0x61498f966D2FecD8D673e097895ec0D025c84E6f";
        break;
      case 'ARBITRUMONE'://gwei: ‘1000000000’
        multiAdd = "0x79593E894C808155D99e683ef2BAFa5D1661fb1E";
        break;
      case 'QKI'://mwei: ‘1000000’
        multiAdd = "0x94bB35F065f055cFc3E9F64b9e57FB2BD743bEc7";
        break;
      case 'HTTEST'://kwei: ‘1000’
        multiAdd = "0x1C097d84f125FA7e2FD73a9377A6A5cd7105D539";
        break;
      case 'TBSC'://Bsc测试链
        multiAdd = "0x76786aD400b85A95b496836E8e27af5510099bEA";
        break;
      case 'THECO'://heco测试链
        multiAdd = "0x1C097d84f125FA7e2FD73a9377A6A5cd7105D539";
        break;
        
    }
    return multiAdd;
  },
  EtoFixed: function (x) {
    var e;
  if (Math.abs(x) < 1.0) {
    e= parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += (new Array(e + 1)).join('0');
    }
  }
  return x;
  },
  getFullNum:function(num){
    //处理非数字
    if (isNaN(num)){return num }
    //处理不需要转换的数字
    var str = '' + num;
    if(!/e/i.test(str)){
      return num;
    }
    return Number(num).toFixed(20).replace(/\.?0+$/,"");
  },
  toFixedDigit: function (num, n)
  {//保留n位
    if (typeof num != 'number') {
      return false;
    }
    if (n <= 0) {//不保留小数，取整
      return false;
    }
    num = num.toString();
    var result = "";
    var zeroResult = function (n) {
      var zero = "";
      for (var i = 0; i < n; i++) {
        zero += "0";
      }
      return zero;
    }
    if (num % 1 == 0) {//整数
      result = num + "." + zeroResult(n);
    } else {//小数
      var num1 = num.split(".");
      if (num1[1].length < n) {
        result = num1[0] + "." + num1[1] + zeroResult(n - num1[1].length)
      } else {
        result = num1[0] + "." + num1[1].substring(0, n)
      }
    }
    return result;
  },
  xml_openSelectionBox() {
    var inputObj = document.createElement('input')
    inputObj.setAttribute('id', 'my_inputObj');
    inputObj.setAttribute('type', 'file');
    inputObj.setAttribute("style", 'visibility:hidden');
    document.body.appendChild(inputObj);

    inputObj.onchange = this.xml_parse(inputObj);//选中文件时触发的方法
    inputObj.click();
  },
  xml_parse(input) {
    console.log("xml_parse", typeof (input.files))
    console.log("xml_parse", input.files)
    //支持chrome IE10  
    if (window.FileReader) {
      console.log("xml_parse", typeof (input.files))
      console.log("xml_parse", input.files)
      var file = input.files[0];
      var reader = new FileReader();
      reader.onload = function (event) {
        var dom = new DOMParser().parseFromString(event.target.result, "text/xml");
        console.log("dom: ", dom);
        //根据自己实际情况进行解析
      }
      if (typeof file === "object") {
        reader.readAsText(file);
        console.log("dom:111 ", file);
      }
      console.log("dom--- ", file);
    }
  }
}