const MultiABI = [
	{
		"constant": false,
		"inputs": [{
			"name": "_token",
			"type": "address"
		}],
		"name": "claim",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	}, {
		"constant": true,
		"inputs": [],
		"name": "getBalanceOfContract",
		"outputs": [{
			"name": "",
			"type": "uint256"
		}],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	}, {
		"constant": true,
		"inputs": [],
		"name": "minFee",
		"outputs": [{
			"name": "",
			"type": "uint256"
		}],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	}, {
		"constant": false,
		"inputs": [{
			"name": "_to",
			"type": "address[]"
		}, {
			"name": "_value",
			"type": "uint256[]"
		}],
		"name": "sendEth",
		"outputs": [{
			"name": "_success",
			"type": "bool"
		}],
		"payable": true,
		"stateMutability": "payable",
		"type": "function"
	}, {
		"constant": false,
		"inputs": [{
			"name": "_value",
			"type": "uint256"
		}],
		"name": "transderToContract",
		"outputs": [],
		"payable": true,
		"stateMutability": "payable",
		"type": "function"
	}, {
		"constant": true,
		"inputs": [],
		"name": "packCount",
		"outputs": [{
			"name": "",
			"type": "uint16"
		}],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	}, {
		"constant": false,
		"inputs": [{
			"name": "_tokenAddress",
			"type": "address"
		}, {
			"name": "_to",
			"type": "address[]"
		}, {
			"name": "_value",
			"type": "uint256[]"
		}],
		"name": "transferErc20",
		"outputs": [{
			"name": "_success",
			"type": "bool"
		}],
		"payable": true,
		"stateMutability": "payable",
		"type": "function"
	}, {
		"constant": true,
		"inputs": [],
		"name": "feePercent",
		"outputs": [{
			"name": "",
			"type": "uint8"
		}],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	}, {
		"constant": true,
		"inputs": [],
		"name": "owner",
		"outputs": [{
			"name": "",
			"type": "address"
		}],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	}, {
		"constant": false,
		"inputs": [{
			"name": "_tokenAddress",
			"type": "address"
		}, {
			"name": "_to",
			"type": "address[]"
		}, {
			"name": "_value",
			"type": "uint256[]"
		}],
		"name": "sendErc20",
		"outputs": [{
			"name": "_success",
			"type": "bool"
		}],
		"payable": true,
		"stateMutability": "payable",
		"type": "function"
	}, {
		"constant": false,
		"inputs": [{
			"name": "_gasValuePrice",
			"type": "uint16"
		}],
		"name": "setGasValuePrice",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	}, {
		"constant": false,
		"inputs": [{
			"name": "_packCount",
			"type": "uint16"
		}],
		"name": "setPackCount",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	}, {
		"constant": true,
		"inputs": [],
		"name": "gasValuePrice",
		"outputs": [{
			"name": "",
			"type": "uint16"
		}],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	}, {
		"constant": false,
		"inputs": [{
			"name": "_feePercent",
			"type": "uint8"
		}],
		"name": "setFeePercent",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	}, {
		"inputs": [],
		"payable": true,
		"stateMutability": "payable",
		"type": "constructor"
	}
]
export { MultiABI }