let chainMapName = new Map([
  ["ETH", { networkId: 1, rpcUrl: "", apiUrl: "", scanUrl:"https://etherscan.io/"}],
  ["BSC", { networkId: 56, rpcUrl: "", apiUrl: "", scanUrl: "https://bscscan.com/" }],
  ["HT", { networkId: 128, rpcUrl: "", apiUrl: "", scanUrl: "https://hecoinfo.com/" }],
  ["OK", { networkId: 66, rpcUrl: "", apiUrl: "", scanUrl: "https://www.oklink.com/okexchain/" }],
  ["MATIC", { networkId: 137, rpcUrl: "", apiUrl: "", scanUrl: "https://polygonscan.com/" }],
  ["CORE", { networkId: 1116, rpcUrl: "", apiUrl: "", scanUrl: "https://scan.coredao.org/" }],
  ["WETH", { networkId: 10001, rpcUrl: "", apiUrl: "", scanUrl: "https://mainnet.ethwscan.com/" }],
  ["ARBITRUMONE", { networkId: 42161, rpcUrl: "", apiUrl: "", scanUrl: "https://explorer.arbitrum.io/" }],
   ["QKI", { networkId: 20181205, rpcUrl: "", apiUrl: "", scanUrl: "https://qkiscan.io/" }],
  ["MBK", { networkId: 12133, rpcUrl: "https://rpcm.mbkscan.com/", apiUrl: "", scanUrl: "https://www.mbkscan.com/" }],
  [12, "共因事件"],
  ["TBSC", { networkId: 97, rpcUrl: "", apiUrl: "", scanUrl: "https://testnet.bscscan.com/" }],
  ["THECO", { networkId: 256, rpcUrl: "", apiUrl: "", scanUrl: "https://testnet.hecoinfo.com/" }],
  ["Mumbai", { networkId: 80001, rpcUrl: "https://rpc-mumbai.maticvigil.com", apiUrl: "", scanUrl: "https://mumbai.polygonscan.com" }],
])
let chainMapNetworkId = new Map([
  [1, { name: "ETH", rpcUrl: "", apiUrl: "", scanUrl: "https://etherscan.io/", gasPrice: 0 }],
  [56, { name: "BSC", rpcUrl: "https://bsc-dataseed1.binance.org/",symbol:'BSC',decimal:18, apiUrl: "", scanUrl: "https://bscscan.com/", gasPrice: 0 }],
  [128, { name: "HT", rpcUrl: "https://http-mainnet-node.defibox.com", symbol: 'HT', decimal: 18, apiUrl: "", scanUrl: "https://hecoinfo.com/", gasPrice: 0 }],
  [66, { name: "OK", rpcUrl: "https://okchain.mytokenpocket.vip/", symbol: 'OK', decimal: 18, apiUrl: "", scanUrl: "https://www.oklink.com/okexchain/", gasPrice: 0 }],
  [137, { name: "MATIC", rpcUrl: "https://rpc-mainnet.maticvigil.com/", symbol: 'MATIC', decimal: 18, apiUrl: "", scanUrl: "https://polygonscan.com/", gasPrice: 0 }],
  [1116, { name: "CORE", rpcUrl: "https://rpc-mainnet.maticvigil.com/", symbol: 'CORE', decimal: 18, apiUrl: "", scanUrl: "https://polygonscan.com/", gasPrice: 0 }],
  [10001, { name: "WETH", rpcUrl: "https://rpc-mainnet.maticvigil.com/", symbol: 'WETH', decimal: 18, apiUrl: "", scanUrl: "https://polygonscan.com/", gasPrice: 0 }],
  [42161, { name: "ARBITRUMONE", rpcUrl: "https://arbitrum-mainnet.infura.io/", symbol: 'ARBONE', decimal: 18, apiUrl: "", scanUrl: "https://polygonscan.com/", gasPrice: 0 }],

  [20181205, { name: "QKI", rpcUrl: "https://hz.node.quarkblockchain.cn", symbol: 'QKI', decimal: 18, apiUrl: "", scanUrl: "https://qkiscan.io/", gasPrice: 0 }],
  [12133, { name: "MBK", rpcUrl: "https://rpcm.mbkscan.com/", symbol: 'MBK', decimal: 18, apiUrl: "", scanUrl: "https://www.mbkscan.com/", gasPrice: 0 }],
  [12, "共因事件"],
  [97, { name: "TBSC", rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/", symbol: 'TBNB', decimal: 18, apiUrl: "", scanUrl: "https://testnet.bscscan.com/", gasPrice: 0 }],
  [256, { name: "THECO", rpcUrl: "https://http-testnet.hecochain.com", symbol: 'THT', decimal: 18, apiUrl: "", scanUrl: "https://testnet.hecoinfo.com/", gasPrice: 0 }],
  [80001, { name: "Mumbai", rpcUrl: "https://rpc-mumbai.maticvigil.com", symbol: 'MATIC', decimal: 18, apiUrl: "", scanUrl: "https://mumbai.polygonscan.com", gasPrice: 0 }],
])
let state = {
  web3: {
    isInjected: false,
    isUnlocked: false,
    web3Instance: null,
    contractInstance: null,
    networkId: 0,
        chainName:null,
        coinbase: null,
    balance: null,
    tokenBalance: null,
        chainCode: null,
    gasPrice: null,
    gasLimit: null,
    unitDecimal: null,
    unitEth:null,
    totalSupply: null,
    packCount:0,
        error: null,
        errorCode: null
    },
  contractInstance: null,
  chainMapName: chainMapName,
  chainMapNetworkId: chainMapNetworkId,
  
  MaxApprove: "100000000000000000000000000000000000",
  accountTextValue: '',
  multiTbItems: [],
  multiTbItemsCurrentIndex:0
}
export default state
