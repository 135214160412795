import Web3 from 'web3';
import metaCoinArtifact from '../Contracts/MetaCoin.json';// eslint-disable-line no-unused-vars
//import metaCoinArtifact from ('./AbiWeb.json');// eslint-disable-line no-unused-vars
//let metaCoinArtifact require('./AbiWeb.json');// eslint-disable-line no-unused-vars
//web3.js
export default {
    contractAddress: 'http://127.0.0.1:7545',// eslint-disable-line no-unused-vars
    
    //const contractABI = []
    //var contract = null;
    //Vue.use(Web3); 
    web3: null,// eslint-disable-line no-unused-vars
    account: null,
    meta: null,
    balance:0,
    start: function () {
        //alert(metaCoinArtifact);
        if (window.ethereum) {
            // use MetaMask's provider
            this.web3 = new Web3(window.ethereum);
            window.ethereum.enable(); // get permission to access accounts
        } else {
            //console.warn(
            //    "No web3 detected. Falling back to http://127.0.0.1:7545. You should remove this fallback when you deploy live",
            //);
            // fallback - use your fallback strategy (local node / hosted node + in-dapp id mgmt / fail)
            this.web3 = new Web3(
                new Web3.providers.HttpProvider(this.contractAddress),
            );
        }
    },
    getCount: async function () {
        const { web3 } = this;
        alert('kkk');
        try {
            const networkId = await web3.eth.net.getId();
            //alert(networkId);
            const deployedNetwork = metaCoinArtifact.networks[networkId];
            const accounts = await web3.eth.getAccounts(function (error, result) {
                if (!error) {
                    console.log("----" + JSON.stringify(result));
                    console.log("----" + result.length);
                }
                else {
                    console.error(error);
                }
                   
            })
            
            this.account = accounts[0];// eslint-disable-line no-unused-vars
            this.refreshBalance();
            this.meta = new web3.eth.Contract(
                metaCoinArtifact.abi,
                deployedNetwork.address,
            );
            // get accounts
            
           
        } catch (error) {
            console.error("Could not connect to contract or chain.");
        }
    },
    refreshBalance: async function () {
        //const totalSupply = await this.meta.methods.totalSupply().call();
        //alert(totalSupply);
        //alert("contract-addr" + this.meta.options.address);
        alert("contract-addr" + this.account);
        //this.meta.methods.getBalance(this.account).call((err, val) => {
        //    console.log({ err, val })
        //    //alert(val);
        //})
         const { getBalance } = this.meta.methods;
         alert(this.account);
        this.balance = await getBalance(this.account).call();
        //this.balance = await getBalance(this.account).call();
         alert(this.balance);
        //const balanceElement = document.getElementsByClassName("balance")[0];
        //balanceElement.innerHTML = this.balance;
    },
}

////������Ӧ�ķ���
//export default {
//    Init,
//    getCount
//}
