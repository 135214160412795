
import lazyLoading from './lazyLoading'

const state = {
items: [
  {
    meta: '首页',
      path: '/home',
    isMenu: true,
    name: 'myHome',
      router: true,
    icon: 'face',
    params: {chain:'myHome'},
      component: lazyLoading('myHome')
    },
    {
      meta: 'Casino',
      path: '/casino',
      isMenu: true,
      name: 'Casino',
      router: true,
    icon: 'computer',
    params: { chain: 'BNB'},
      component: lazyLoading('Casino')
    },
    {
      meta: '批量转账ETH',
      path: '/batchTransfer/eth',
      isMenu: true,
      name: 'ETH',
      router: true,
      params:{ chain:'ETH'},
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账BSC',
      path: '/batchTransfer/bsc',
      isMenu: true,
      name: 'BSC',
      router: true,
      params: { chain: 'BSC' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账HT',
      path: '/batchTransfer/ht',
      isMenu: true,
      name: 'HT',
      router: true,
      params: { chain: 'HT' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账OK',
      path: '/batchTransfer/ok',
      isMenu: true,
      name: 'OK',
      router: true,
      params: { chain: 'OK' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账MATIC(Polygon)',
      path: '/batchTransfer/matic',
      isMenu: true,
      name: 'MATIC',
      router: true,
      params: { chain: 'MATIC' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账CoreDao',
      path: '/batchTransfer/core',
      isMenu: true,
      name: 'CORE',
      router: true,
      params: { chain: 'CORE' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账WETH',
      path: '/batchTransfer/weth',
      isMenu: true,
      name: 'WETH',
      router: true,
      params: { chain: 'WETH' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账ArbitrumOne',
      path: '/batchTransfer/ArbitrumOne',
      isMenu: true,
      name: 'ARBITRUMONE',
      router: true,
      params: { chain: 'ARBITRUMONE' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账QKI',
      path: '/batchTransfer/qki',
      isMenu: true,
      name: 'QKI',
      router: true,
      params: { chain: 'QKI' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账MBK',
      path: '/batchTransfer/mbk',
      isMenu: true,
      name: 'MBK',
      router: true,
      params: { chain: 'MBK' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账TBSC',
      path: '/batchTransfer/tbsc',
      isMenu: true,
      name: 'TBSC',
      router: true,
      params: { chain: 'TBSC' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: '批量转账THECO',
      path: '/batchTransfer/theco',
      isMenu: true,
      name: 'THECO',
      router: true,
      params: { chain: 'THECO' },
      icon: 'create',
      component: lazyLoading('BatchTransfer')
    },
    {
      meta: 'About',
      path: '/about',
      isMenu: true,
      name: 'myAbout',
      router: true,
      icon: 'view_day',
     
    params: { chain: 'OKT'},
      component: lazyLoading('myAbout')
    },
    {
      meta: '合约安全检测',
      path: '/contractGoPlusCheck',
      isMenu: true,
      name: 'ContractGoPlusCheck',
      router: true,
      icon: 'view_day',

      params: { chain: 'OKT' },
      component: lazyLoading('ContractGoPlusCheck')
    },
    {
      meta: 'NFT一键发布',
      path: '/nFTMain',
      isMenu: true,
      name: 'NFTMain',
      router: true,
      icon: 'view_day',

      params: { chain: 'OKT' },
      component: lazyLoading('NFTMain')
    }
]
}

export default {
    state
}
