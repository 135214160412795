// 基本事件类型ID
const chainMap = new Map([
  [1, "基本事件"],
  [2, "房形事件"],
  [3, "门事件"],
  [4, "后果事件"],
  [5, "始发事件"],
  [6, "功能事件"],
  [12, "共因事件"],
  [21, "共因门事件"],
])
export default chainMap