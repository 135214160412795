import Web3 from 'web3'
import store  from '../store/'

let pollWeb3 = function () {
  let web3 = window.web3
  web3 = new Web3(web3.currentProvider)
  console.log("pollStart")
  setInterval(() => {
    console.log("setTimeOut---Interval")
    if (web3 && store.state.web3.web3Instance) {
      web3.eth.getCoinbase((err, coinbase) => {
        if (err) {
          //reject(new Error('Unable to retrieve coinbase'));

        } else {
          console.log(coinbase)
          let newCoinbase = coinbase
          if (newCoinbase !== store.state.web3.coinbase) {
            web3.eth.getBalance(newCoinbase, function (err, newBalance) {
              if (err) {
                console.log(err)
              } else {
                store.dispatch('pollWeb3', {
                  coinbase: newCoinbase,
                  balance: parseInt(newBalance, 10)
                })
              }
            })
          } else {
            web3.eth.getBalance(store.state.web3.coinbase, (err, polledBalance) => {
              if (err) {
                console.log(err)
              } else if (parseInt(polledBalance, 10) !== store.state.web3.balance) {
                store.dispatch('pollWeb3', {
                  coinbase: store.state.web3.coinbase,
                  balance: polledBalance
                })
              }
            })
          }
        }
      })
        //if (web3.eth.coinbase !== store.state.web3.coinbase) {
            
            
        //} else {
            
        //}
    }
  }, 1800)
}

export default pollWeb3
